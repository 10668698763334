import SVLoadingIndicator from '@pkgs/shared-client/components/SVLoadingIndicator';
import React, { Suspense, lazy } from 'react';

// Lazy loaded because media-chrome brings 50kb to the main bundle
const SVMinimalVideoPlayerContents = lazy(() => import('./SVMinimalVideoPlayerContents'));

const SVMinimalVideoPlayer = (props: React.ComponentProps<typeof SVMinimalVideoPlayerContents>) => {
	return (
		<Suspense
			fallback={
				<div className="flex-center relative h-full w-full">
					<SVLoadingIndicator />
				</div>
			}
		>
			<SVMinimalVideoPlayerContents {...props} />
		</Suspense>
	);
};

export default SVMinimalVideoPlayer;
